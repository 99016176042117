import React from 'react';
import './BlogPost.css';

const BlogPost = () => {
  return (
    <div className="blog-post-detail">
      <h1>CBSE Class 10: Understanding DNA Studies</h1>
      <p className="date">Published on July 14, 2024</p>
      
      <div className="content">
        <h2>Introduction to DNA</h2>
        <p>
          DNA, or Deoxyribonucleic Acid, is the hereditary material in all living organisms. It contains the instructions needed for an organism to develop, survive, and reproduce. In CBSE Class 10, students are introduced to the fundamental concepts of DNA, its structure, and its functions.
        </p>

        <h2>Structure of DNA</h2>
        <p>
          DNA is composed of two long strands that form a double helix. Each strand is made up of smaller units called nucleotides. A nucleotide consists of three components:
        </p>
        <ul>
          <li>A phosphate group</li>
          <li>A sugar molecule (deoxyribose)</li>
          <li>A nitrogenous base (adenine, thymine, cytosine, or guanine)</li>
        </ul>
        <p>
          The sequence of these nitrogenous bases encodes genetic information. Adenine (A) pairs with thymine (T), and cytosine (C) pairs with guanine (G), forming base pairs that hold the two strands together.
        </p>

        <h2>Functions of DNA</h2>
        <p>
          DNA is crucial for various biological functions, including:
        </p>
        <ul>
          <li><strong>Genetic Information Storage:</strong> DNA stores the genetic blueprint of an organism, determining its characteristics and traits.</li>
          <li><strong>Replication:</strong> Before a cell divides, it must replicate its DNA so that each new cell receives an identical copy.</li>
          <li><strong>Protein Synthesis:</strong> DNA provides the instructions for building proteins, which are essential for many cellular processes.</li>
        </ul>

        <h2>DNA in Inheritance</h2>
        <p>
          DNA plays a key role in inheritance, as it is passed from parents to offspring. Each parent contributes half of the DNA to their offspring, ensuring a combination of genetic traits. This process explains the similarities and differences between parents and their children.
        </p>

        <h2>Conclusion</h2>
        <p>
          Understanding DNA is fundamental to comprehending how living organisms function and how traits are passed from one generation to the next. The study of DNA in CBSE Class 10 lays the groundwork for more advanced topics in biology and genetics.
        </p>
      </div>
    </div>
  );
};

export default BlogPost;
