// src/components/SocialMedia.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faXTwitter,faInstagram, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import './SocialMedia.css';


const SocialMedia = () => {
    const whatsappMessage = "Hello! I'm interested in learning more about your Ekademic platform and the private lessons you offer. Can you please provide more details?";

  return (
    <div className="social-media">
      <a href="https://www.facebook.com/profile.php?id=61562485226406" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebook} />
      </a>
      <a href="https://x.com/ekademic" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faXTwitter} />
      </a>
      <a href="https://www.instagram.com/ekademic_official/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a href={`https://wa.me/9380407853?text=${encodeURIComponent(whatsappMessage)}`} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
      <a href="https://www.youtube.com/@ekademic" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faYoutube} />
      </a>
    </div>
  );
}

export default SocialMedia;
