import React, { useState } from 'react';
import './Courses.css';

const Courses = () => {
  const [activeTab, setActiveTab] = useState('free');
  const generateWhatsAppLink = (title) => {
    const phoneNumber = "+919380407853"; // Your WhatsApp phone number
    const message = encodeURIComponent(`Hi, I'm interested in the "${title}" course. Could you provide more details?`);
    return `https://wa.me/${phoneNumber}?text=${message}`;
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'free':
        return (
          <div className="course-section">
            <div className="course-card">
              <i className="fas fa-paint-brush course-icon"></i>
              <h3 className="course-title">MERN Stack Development</h3>
              <p className="course-description">
                Learn the essentials of User Interface and User Experience design to create engaging and user-friendly applications.
              </p>
              <a href={generateWhatsAppLink('UI/UX Development')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="course-card">
              <i className="fas fa-mobile-alt course-icon"></i>
              <h3 className="course-title">Mobile Apps Development</h3>
              <p className="course-description">
                Develop cutting-edge mobile applications for both Android and iOS platforms with our comprehensive course.
              </p>
              <a href={generateWhatsAppLink('Mobile Apps Development')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="course-card">
              <i className="fas fa-robot course-icon"></i>
              <h3 className="course-title">Python Advanced Course</h3>
              <p className="course-description">
                Integrate Artificial Intelligence and Machine Learning into your applications to enhance their capabilities.
              </p>
              <a href={generateWhatsAppLink('AI/ML Integration')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="course-card">
              <i className="fas fa-cloud course-icon"></i>
              <h3 className="course-title">Cloud Computing</h3>
              <p className="course-description">
                Learn how to host and manage your applications on the cloud with our in-depth cloud hosting course.
              </p>
              <a href={generateWhatsAppLink('Cloud Hosting')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="course-card">
              <i className="fas fa-database course-icon"></i>
              <h3 className="course-title">Database Design</h3>
              <p className="course-description">
                Master the principles of database design to create efficient and scalable databases for your applications.
              </p>
              <a href={generateWhatsAppLink('Database Design')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="course-card">
              <i className="fas fa-language course-icon"></i>
              <h3 className="course-title">React JS</h3>
              <p className="course-description">
              Learn HTML5, CSS3, ES6, Component, Redux, Node API.
              </p>
              <a href={generateWhatsAppLink('React JS')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
          </div>
        );
      case 'paid':
        return (
          <div className="course-section">
            <div className="course-card">
              <i className="fas fa-microphone-alt course-icon"></i>
              <h3 className="course-title">Public Speaking</h3>
              <p className="course-description">
                Enhance your public speaking skills with our comprehensive course designed to build confidence and clarity.
              </p>
              <a href={generateWhatsAppLink('Public Speaking')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="course-card">
              <i className="fas fa-Corporate Training course-icon"></i>
              <h3 className="course-title">Corporate Training</h3>
              <p className="course-description">
                Learn the strategies and techniques of Corporate Training to improve your game and compete at a higher level.
              </p>
              <a href={generateWhatsAppLink('Corporate Training')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="course-card">
              <i className="fas fa-cube course-icon"></i>
              <h3 className="course-title">Rubik's Cube</h3>
              <p className="course-description">
                Solve the Rubik's Cube with ease by mastering the algorithms and techniques covered in our course.
              </p>
              <a href={generateWhatsAppLink('Rubiks Cube')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="course-card">
              <i className="fas fa-pencil-alt course-icon"></i>
              <h3 className="course-title">Sketching</h3>
              <p className="course-description">
                Develop your sketching skills with our course that covers various techniques and styles for all skill levels.
              </p>
              <a href={generateWhatsAppLink('Sketching')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
          </div>
        );
      case 'resources':
        return (
          <div className="course-section">
            <div className="resource-card">
              <i className="fas fa-book course-icon"></i>
              <h3 className="resource-title">Mathematics</h3>
              <p className="resource-description">
                Access a range of mathematical resources, including eBooks and worksheets, to enhance your understanding.
              </p>
              <a href={generateWhatsAppLink('Mathematics')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="resource-card">
              <i className="fas fa-flask course-icon"></i>
              <h3 className="resource-title">Science</h3>
              <p className="resource-description">
                Explore scientific concepts and principles with our curated collection of resources and materials.
              </p>
              <a href={generateWhatsAppLink('Science')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="resource-card">
              <i className="fas fa-language course-icon"></i>
              <h3 className="resource-title">Sanskrit</h3>
              <p className="resource-description">
                Learn the classical language of Sanskrit with our comprehensive resources designed for all levels.
              </p>
              <a href={generateWhatsAppLink('Sanskrit')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="resource-card">
              <i className="fas fa-globe course-icon"></i>
              <h3 className="resource-title">Urdu</h3>
              <p className="resource-description">
                Enhance your understanding of Urdu with our extensive resources covering grammar, vocabulary, and more.
              </p>
              <a href={generateWhatsAppLink('Urdu')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="resource-card">
              <i className="fas fa-book-open course-icon"></i>
              <h3 className="resource-title">Hindi</h3>
              <p className="resource-description">
                Improve your Hindi language skills with our collection of resources, including textbooks and practice sheets.
              </p>
              <a href={generateWhatsAppLink('Hindi')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="resource-card">
              <i className="fas fa-file-alt course-icon"></i>
              <h3 className="resource-title">English</h3>
              <p className="resource-description">
                Access a variety of English language resources to enhance your reading, writing, and comprehension skills.
              </p>
              <a href={generateWhatsAppLink('English')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="resource-card">
              <i className="fas fa-landmark course-icon"></i>
              <h3 className="resource-title">History</h3>
              <p className="resource-description">
                Delve into historical events and periods with our curated resources, including articles, videos, and more.
              </p>
              <a href={generateWhatsAppLink('History')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="resource-card">
              <i className="fas fa-globe-americas course-icon"></i>
              <h3 className="resource-title">Geography</h3>
              <p className="resource-description">
                Explore the world's geography with our comprehensive resources, including maps, articles, and quizzes.
              </p>
              <a href={generateWhatsAppLink('Geography')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="resource-card">
              <i className="fas fa-balance-scale course-icon"></i>
              <h3 className="resource-title">Polity</h3>
              <p className="resource-description">
                Understand political systems and structures with our detailed resources, perfect for students and enthusiasts.
              </p>
              <a href={generateWhatsAppLink('Polity')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="resource-card">
              <i className="fas fa-atom course-icon"></i>
              <h3 className="resource-title">Physics</h3>
              <p className="resource-description">
                Dive into the world of physics with our range of resources, including experiments, articles, and videos.
              </p>
              <a href={generateWhatsAppLink('Physics')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
            <div className="resource-card">
              <i className="fas fa-vials course-icon"></i>
              <h3 className="resource-title">Chemistry</h3>
              <p className="resource-description">
                Learn about chemical reactions, compounds, and more with our detailed chemistry resources.
              </p>
              <a href={generateWhatsAppLink('Chemistry')} target="_blank" rel="noopener noreferrer" className="enroll-button">Enroll</a>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  

  return (
    <section className="courses">
      <div className="container">
        <h1 className="courses-title">Our Courses</h1>
        <div className="tabs">
          <button className={`tab ${activeTab === 'free' ? 'active' : ''}`} onClick={() => setActiveTab('free')}>
            Master Coding
          </button>
          <button className={`tab ${activeTab === 'paid' ? 'active' : ''}`} onClick={() => setActiveTab('paid')}>
            Skill Development
          </button>
          <button className={`tab ${activeTab === 'resources' ? 'active' : ''}`} onClick={() => setActiveTab('resources')}>
            Extra Classes (6-9)
          </button>
        </div>
        <div className="courses-content">
          {renderTabContent()}
        </div>
      </div>
    </section>
  );
};

export default Courses;
