import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import './Header.css';
import ContactUs from './ContactUs';
// import FreeTrialPopup from './FreeTrialPopup';
import FloatingWhatsAppIcon from './FloatingWhatsAppIcon';
import FloatingCallIcon from './FloatingCallIcon';
import logoImage from './assets/logon.png'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    const whatsappMessage = "Hi there! 👋 I'm interested in booking a free trial class for Ekademic. Could you please let me know the available slots and how I can join? Thanks!";
    window.open(`https://wa.me/9380407853?text=${encodeURIComponent(whatsappMessage)}`, '_blank');
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleCall = () => {
    window.location.href = 'tel:9380407853';
  };

  return (
    <header className="header">
      <div className="container">
        <div className="top-bar">
          <div className="hamburger" onClick={toggleMenu}>
            &#9776;
          </div>
          <div className="logo-container">
            <img src={logoImage} alt="Ekademic Logo" className="logo-image" />
            <div className="logo">Ekademic</div>
          </div>
          <nav className="nav-desktop">
            <ul>
              <li>
                <RouterLink to="/" className="link-hover">
                  Home
                </RouterLink>
              </li>
              <li>
              <RouterLink to="/courses" className="link-hover">
              Courses
                </RouterLink>
              </li>
              <li>
              <RouterLink to="/services" className="link-hover">
              Services
                </RouterLink>
              </li>
              <li>
                <RouterLink to="/blogs" className="link-hover">
                  Blog
                </RouterLink>
              </li>
              <li>
                <Link to="about" smooth={true} duration={500} className="link-hover">
                  About Us
                </Link>
              </li>
              <li style={{ cursor: 'pointer', color: 'black', fontWeight: 600 }} onClick={openModal} className="link-hover">
                Contact Us
              </li>
            </ul>
          </nav>
          <button onClick={handleOpenPopup} className="start-trial-button">Get Free Counseling</button>
          {/* <button onClick={handleCall} className="call-button">
              <FontAwesomeIcon icon={faPhoneAlt} />
            </button> */}
        </div>
        <nav className={`nav-mobile ${isMenuOpen ? 'open' : ''}`}>
          <div className="close-icon" onClick={closeMenu}>
            &times;
          </div>
          <ul>
            <li>
              <RouterLink to="/" className="link-hover" onClick={closeMenu}>
                Home
              </RouterLink>
            </li>
            <li>
            <RouterLink to="/courses" className="link-hover" onClick={closeMenu}>
              Courses
                </RouterLink>
            </li>
            <li>
            <RouterLink to="/services" className="link-hover" onClick={closeMenu}>
              Services
                </RouterLink>
            </li>
            <li>
              <RouterLink to="/blogs" className="link-hover" onClick={closeMenu}>
                Blog
              </RouterLink>
            </li>
            <li>
              <Link to="about" smooth={true} duration={500} className="link-hover" onClick={closeMenu}>
                About Us
              </Link>
            </li>
            <li style={{ cursor: 'pointer', color: 'black', fontWeight: 600 }} onClick={() => { openModal(); closeMenu(); }} className="link-hover">
              Contact Us
            </li>
          </ul>
        </nav>
        <FloatingWhatsAppIcon />
        <FloatingCallIcon />
        <ContactUs isOpen={modalIsOpen} onRequestClose={closeModal} />
      </div>
    </header>
  );
};

export default Header;
