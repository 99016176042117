// frontend/src/Register.js
import React, { useState } from 'react';
// import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import './Register.css';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const register = async () => {
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    try {
    //   const response = await Auth.signUp({
    //     username: email,
    //     password,
    //     attributes: {
    //       email
    //     }
    //   });
      setMessage('Registration successful! Please check your email for confirmation.');
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="register-page">
      <div className="promo-section">
        <div className="promo-content">
          <h2>Unlocking academic success for every student.</h2>
          <p>SAVE UP TO 90%</p>
          <p> Excel in your exams with personalized learning experiences</p>
        </div>
      </div>
      <div className="form-section">
        <div className="register-container">
          <h1>Sign in to your Ekademic account</h1>
          <form className="register-form">
            <input
              type="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button type="button" onClick={register}>Sign Up</button>
          </form>
          <div className="social-buttons">
            <button className="social-button apple">Apple</button>
            <button className="social-button facebook">Facebook</button>
            <button className="social-button google">Google</button>
          </div>
          <p className="divider">OR</p>
          <p className="message">{message}</p>
          <p className="login-link">
            Don't have an account? <Link to="/register">Sign up</Link>
          </p>
          <p className="forgot-link">
            <Link to="/forgot-password">Forgotten password?</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
