// AboutUs.js

import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <section id="about" className="about-section">
      <div className="container2">
        <h2>About Us</h2>
        <p>At Ekademic, we are dedicated to empowering individuals and businesses through comprehensive education and cutting-edge digital solutions. Our platform offers a wide range of expertly designed courses, from mastering coding and academic subjects to excelling in competitions. We also provide top-notch services in mobile app development, website creation, and custom software solutions. Our mission is to bridge the gap between learning and practical application, helping our clients and students achieve their goals with confidence and expertise.</p>
        <h3>Our Mission</h3>
        <p>Our mission is to provide unparalleled educational experiences and digital services that empower individuals and businesses to reach their full potential. We strive to create a supportive and innovative environment where students can master new skills, achieve academic success, and confidently compete in any arena. For our business clients, we aim to deliver tailored digital solutions that drive growth, enhance user experiences, and solve real-world challenges.</p>
        <h3>Our Vision</h3>
        <p>Our vision is to be a leading provider of holistic learning experiences and digital transformation services, recognized for our commitment to quality, innovation, and customer success. We aspire to create a global community of learners and businesses who are equipped with the tools and knowledge to thrive in an ever-evolving world. Through our courses and services, we aim to shape a future where education and technology work hand-in-hand to unlock endless possibilities.</p>
      </div>
    </section>
  );
}

export default AboutUs;
