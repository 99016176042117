import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import Register from './Register';
import BlogPost from './BlogPost';
import BlogPage from './Blog/BlogPage';
import BlogDetailPage from './Blog/BlogDetailPage';
import Ribbon from './Ribbon';
import Header from './Header';
import llmImage from './assets/LLM.jpg';
import pcImage from './assets/python.jpg';
import aiImage from './assets/AI.jpg';
import Services from './Services';
import Courses from './Courses';
import Footer from './Footer';
import TermsAndConditions from './TermsAndConditions';

function App() {
  // const [blogs, setBlogs] = React.useState([]);



  const [blogs, setBlogs] = useState([
    { id: 1, title: 'Finetuning LLMs', description: 'Learn to finetune your LLMs within minutes', category: 'Artificial Intelligence Course', image: llmImage ,meta:"Posted by GenAI expert | July 25, 2024"},
    { id: 2, title: 'Python for everyone', description: 'Learn to code in Python', category: 'Technology', image: pcImage ,meta:"Posted by Python expert | July 25, 2024"},
    { id: 3, title: 'AI Fundamentals', description: 'Learn AI', category: 'Artificial Intelligence Course', image: aiImage ,meta:"Posted by AI expert | July 25, 2024"},
   
  ]);

    const addBlog = (newBlog) => {
    setBlogs([...blogs, newBlog]);
  };

  return (
    <Router>
      <div>
        {/* <Ribbon /> Add the Ribbon component here */}
        <Header />
        <Routes>
          <Route path="/blogs" element={<BlogPage blogs={blogs} addBlog={addBlog} />} />
          <Route path="/blogs/:id" element={<BlogDetailPage blogs={blogs} />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/blog-post" element={<BlogPost />} />
          <Route path="/services" element={<Services/>} />
          <Route path="/courses" element={<Courses/>} />
          <Route path="/terms"  element={<TermsAndConditions/>} />
        </Routes>
        <Footer></Footer>
      </div>
    </Router>
  );
}

export default App;