// src/components/Footer.js
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import './Footer.css';
import ContactUs from './ContactUs';
import SocialMedia from './SocialMedia';

const Footer = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <footer className="footer">
      <div className="footer-links">
        <ul>
        <li>
                <RouterLink to="/" className="link-hover">
                  Home
                </RouterLink>
              </li>
          <li>
          <RouterLink to="/courses" className="link-hover">
              Courses
                </RouterLink>
          </li>
          <li>
              <RouterLink to="/services" className="link-hover">
              Services
                </RouterLink>
              </li>
          {/* <li>
            <Link to="pricing" smooth={true} duration={500}>Pricing</Link>
          </li> */}
          <li>
          <RouterLink to="/blogs" className="link-hover">
                  Blog
                </RouterLink>
           
          </li>
          <li>
            <Link to="about" smooth={true} duration={500}  className="link-hover">About Us</Link>
          </li>
          <li style={{ cursor: 'pointer' }} onClick={openModal}>Contact Us</li>
          <li>
          <RouterLink to="/terms" className="link-hover">
              Terms and Conditions
            </RouterLink>
          </li>
        </ul>
      </div>
      <SocialMedia />
      <div className="contact-info">
        <p>Email: contact@ekademic.com</p>
        <p>Phone: +91 9380407853</p>
        <p>Address: Bangalore, Karnatka, IN</p>
      </div>
      <div className="copyright">
        <p>&copy; 2024 Ekademic. All rights reserved.</p>
      </div>
      <ContactUs isOpen={modalIsOpen} onRequestClose={closeModal} />
    </footer>
  );
}

export default Footer;
