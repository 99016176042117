import React ,{useState }from 'react';
import './BlogSection.css';
import dnaImage from './assets/dna.jpg';
import rcImage from './assets/rc.jpg';
import pcImage from './assets/pc.png';
import { Link } from 'react-router-dom'; // Correct import statement

const BlogSection = () => {
 
  const handleReadMore = () => {
    window.open('https://en.wikipedia.org/wiki/DNA', '_blank');
  };

  const handleReadMoreP = () => {
    // setRedirectToBlogs(true); // Navigate to '/blogs' route
  };

  const handleReadMoreRC = () => {
    window.open('https://en.wikipedia.org/wiki/Rubik%27s_Cube', '_blank');
  };
 
  return (
    <section className="blog">
      <div className="container1">
        <h2>Latest from Our Blog</h2>
        <div className="blog-posts">
          <div className="blog-post">
            <img src={dnaImage} alt="DNA Structure" />
            <div className="post-content">
              <h3>CBSE Class 10: Understanding DNA Studies</h3>
              <p className="post-meta">Posted by Science Teacher | July 14, 2024</p>
              <p>
                DNA, or Deoxyribonucleic Acid, is the hereditary material in all living organisms. It contains the instructions needed for an organism to develop, survive, and reproduce. In CBSE Class 10, students are introduced to the fundamental concepts of DNA, its structure, and its functions.
              </p>
              <Link to="/blogs">
              <button >Read More</button>
              </Link>
            
            </div>
          </div>

          <div className="blog-post">
            <img src={pcImage} alt="Python Development" />
            <div className="post-content">
              <h3>Introduction to Python Development</h3>
              <p className="post-meta">Posted by Dev Team | July 14, 2024</p>
              <p>
                Python is a versatile and powerful programming language that is widely used in various fields, including web development, data science, artificial intelligence, scientific computing, and more. It is known for its readability, simplicity, and vast ecosystem of libraries and frameworks.
              </p>
              <button onClick={handleReadMoreP}>Read More</button>
            </div>
          </div>

          <div className="blog-post">
            <img src={rcImage} alt="Rubik's Cube" />
            <div className="post-content">
              <h3>How to Solve a Rubik's Cube</h3>
              <p className="post-meta">Posted by Puzzle Expert | July 14, 2024</p>
              <p>
                The Rubik's Cube is a 3D combination puzzle that has fascinated people around the world for decades. Solving the Rubik's Cube involves understanding its structure, learning a series of algorithms, and practicing to improve your speed. This article covers the basic steps to get you started on solving this iconic puzzle.
              </p>
              <button onClick={handleReadMoreRC}>Read More</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
