import React from 'react';
import './HowItWorks.css';

const HowItWorks = () => {
  return (
    <section className="how-it-works">
      <h2>How It Works</h2>
      <div className="steps">
        <div className="step">
          <div className="step-icon">1</div>
          <h3>Sign Up</h3>
          <p>Create an account in minutes.</p>
        </div>
        <div className="step">
          <div className="step-icon">2</div>
          <h3>Choose a Course</h3>
          <p>Select from a variety of subjects.</p>
        </div>
        <div className="step">
          <div className="step-icon">3</div>
          <h3>Book a Lesson</h3>
          <p>Schedule your private lesson.</p>
        </div>
        <div className="step">
          <div className="step-icon">4</div>
          <h3>Start Learning</h3>
          <p>Engage with expert tutors in real-time.</p>
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;
