// src/components/BlogDetailPage.js
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import './BlogDetailPage.css';

const BlogDetailPage = ({ blogs }) => {
  const { id } = useParams();
  const blog = blogs.find(blog => blog.id === parseInt(id, 10));

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <div className="blog-detail">
      <div className="breadcrumb">
        <Link to="/">Home</Link> / <Link to="/blogs">Blogs</Link> / {blog.title}
      </div>
      <h1>{blog.title}</h1>
      <img src={blog.image} alt={blog.title} className="blog-image" />
      <p>Coming Soon...</p>
    </div>
  );
};

export default BlogDetailPage;
