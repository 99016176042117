import React from 'react';
import './NewsletterSignup.css';

const NewsletterSignup = () => {
  return (
    <section className="newsletter">
      <h2>Stay Updated</h2>
      <form>
        <input type="email" placeholder="Your email" />
        <button type="submit">Subscribe</button>
      </form>
    </section>
  );
}

export default NewsletterSignup;
