import React from 'react';
import './Services.css';
import { FaCloud, FaCode, FaCogs } from 'react-icons/fa';

const Services = () => {
  const services = [
    {
      title: 'Website Development',
      description: 'Reliable and secure hosting solutions for your website.',
      icon: <FaCloud />,
    },
    {
      title: 'Application Development',
      description: 'Custom application development tailored to your business needs.',
      icon: <FaCode />,
    },
    {
      title: 'Custom Software',
      description: 'Bespoke software solutions to streamline your operations.',
      icon: <FaCogs />,
    },
  ];

  const handleGetQuote = (serviceTitle) => {
    const phoneNumber = "+919380407853"; // Your WhatsApp phone number
    const message = encodeURIComponent(`Requesting a quote for ${serviceTitle} Could you provide more details?`);
    return `https://wa.me/${phoneNumber}?text=${message}`;
  };

  return (
    <section className="services">
      <div className="container">
        <h2 className="services-title">Our Services</h2>
        <div className="services-grid">
          {services.map((service, index) => (
            <div className="service-card" key={index}>
              <div className="service-content">
                <div className="service-icon">{service.icon}</div>
                <h3 className="service-title">{service.title}</h3>
                <p className="service-description">{service.description}</p>
                {/* <button
                  className="service-button"
                  onClick={() => handleGetQuote(service.title)}
                >
                  Get a Quote
                </button> */}
                <a href={handleGetQuote(service.title)} target="_blank" rel="noopener noreferrer" className="service-button">Get a Quote</a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
