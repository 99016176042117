// src/components/BlogPage.js
import React, { useState } from 'react';
import BlogList from './BlogList';
import './BlogPage.css';
import aiImage from '../assets/AI.jpg';
import pcImage from '../assets/python.jpg';
import llmImage from '../assets/LLM.jpg';


const BlogPage = () => {
  const [blogs, setBlogs] = useState([
   
    { id: 1, title: 'Finetuning LLMs', description: 'Learn to finetune your LLMs within minutes', category: 'Artificial Intelligence Course', image: llmImage ,meta:"Posted by GenAI expert | July 25, 2024"},
    { id: 2, title: 'Python for everyone', description: 'Learn to code in Python', category: 'Technology', image: pcImage ,meta:"Posted by Python expert | July 25, 2024"},
    { id: 3, title: 'AI Fundamentals', description: 'Learn AI', category: 'Artificial Intelligence Course', image: aiImage ,meta:"Posted by AI/ML expert | July 25, 2024"},
   
    // { id: 2, title: 'Django Course Essentials', description: 'Django is an industry-favourite...', category: 'Programming', image: rcImage },
    // { id: 3, title: 'Learn Python from Scratch', description: 'Python is the most versatile...', category: 'Programming', image: pcImage },
 
    // { id: 4, title: 'Webflow Web Design', description: 'Webflow is fast becoming...', category: 'Web Design', image: 'https://via.placeholder.com/300x150?text=Webflow+Web+Design' },
    // { id: 5, title: 'Django Course Essentials', description: 'Django is an industry-favourite...', category: 'Programming', image: 'https://via.placeholder.com/300x150?text=Django+Course+Essentials' },
    // { id: 6, title: 'Learn Python from Scratch', description: 'Python is the most versatile...', category: 'Programming', image: 'https://via.placeholder.com/300x150?text=Learn+Python+from+Scratch' },
   
    // { id: 7, title: 'Django Course Essentials', description: 'Django is an industry-favourite...', category: 'Programming', image: 'https://via.placeholder.com/300x150?text=Django+Course+Essentials' },
    // { id: 8, title: 'Learn Python from Scratch', description: 'Python is the most versatile...', category: 'Programming', image: 'https://via.placeholder.com/300x150?text=Learn+Python+from+Scratch' },
   
    // { id: 9, title: 'Django Course Essentials', description: 'Django is an industry-favourite...', category: 'Programming', image: 'https://via.placeholder.com/300x150?text=Django+Course+Essentials' },
    // { id: 10, title: 'Learn Python from Scratch', description: 'Python is the most versatile...', category: 'Programming', image: 'https://via.placeholder.com/300x150?text=Learn+Python+from+Scratch' },

  ]);

  const [searchCategory, setSearchCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6;

  const filteredBlogs = blogs.filter(blog =>
    blog.category.toLowerCase().includes(searchCategory.toLowerCase())
  );

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredBlogs.slice(indexOfFirstPost, indexOfLastPost);

  const totalPages = Math.ceil(filteredBlogs.length / postsPerPage);

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // const categories = [
  //   'Android courses', 'Angular', 'App Development', 'Artificial Intelligence Course',
  //   'ASP.Net', 'Blockchain Course', 'Career', 'Cloud Computing Course',
  //   'Content Writing', 'Course', 'Crash Course', 'Database', 'Digital Marketing',
  //   'Django Courses', 'Education', 'Embedded System and Robotics Course',
  //   'Ethical Hacking and Cyber Security Course', 'Flutter', 'Game Development',
  //   'Google', 'How To', 'Internet of Things (IoT) Course', 'iOS App Development',
  //   'iPhone courses', 'IT Certification Course', 'Java', 'Machine Learning with Python Course',
  //   'Mean stack', 'Mern stack', 'Mobile App Development Course', 'Mongo',
  //   'NET Development', 'Networking with Cisco Tools Course', 'News', 'Node JS',
  //   'PHP', 'PHP Variable', 'Professional IT course', 'Python Course', 'React Native',
  //   'SAP Development', 'Social Media', 'Software Testing', 'Successful Career',
  //   'Tech Industry', 'Tech News & Tips', 'Technology'
  // ];

  const categories = [
    'Artificial Intelligence Course',
   'Career Councelling', 'Cloud Computing Course',
    'Database', 
    'Django Courses', 'Education',  
    'Professional IT course', 'Python Course', 'React Native',
   'Software Testing',  'Technology'
  ];


  return (
    <div className="blog-page">
      <div className="blog-list-container">
        {/* <h1>Blogs</h1> */}
        <BlogList blogs={currentPosts} />
        {/* <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
              onClick={() => handlePagination(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div> */}
      </div>
      <div className="search-bar-container">
        <input
          type="text"
          placeholder="Search by category"
          value={searchCategory}
          onChange={(e) => setSearchCategory(e.target.value)}
          className="search-bar"
        />
        <ul className="category-list">
          {categories.map((category, index) => (
            <li key={index} onClick={() => setSearchCategory(category)}>
              &#8226; {category}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BlogPage;
