import React from 'react';
import './HomePage.css';
import tutor from './tutor.png'; 
import cl from './cl.png'; 
import fs from './fs.png'; 
import ap from './ap.png'; 
import sc from './sc.png';
import ec from './ec.png';
import pr from './pr.png';
import HowItWorks from './HowItWorks';
import Testimonials from './Testimonials';
import TutorShowcase from './TutorShowcase';
import Footer from './Footer';
import NewsletterSignup from './NewsletterSignup';
import BlogSection from './BlogSection';
import Header from './Header';
import HeroSection from './HeroSection';
import Pricing from './Pricing';
import AboutUs from './AboutUs';

function HomePage() {

  const openWhatsAppSC = () => {
    const phoneNumber = '9380407853'; // Replace with your phone number
     const message = 'I am excited to get started with your educational platform! I am interested in learning more about your School Curriculum and Career Counseling services. Looking forward to your response!';
    
     const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
 };
 const openWhatsAppP = () => {
  const phoneNumber = '9380407853'; // Replace with your phone number

  const message = 'I am excited to get started with your educational platform! I am interested in learning more about your offered Programming courses. Looking forward to your response!';
    
   const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  window.open(url, '_blank');
};
const openWhatsAppEC = () => {
  const phoneNumber = '9380407853'; // Replace with your phone number
  const message = 'I am excited to get started with your educational platform! I am interested in learning more about your Extracurricular courses. Looking forward to your response!';
    
   const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  window.open(url, '_blank');
};

  return (
    <div className="App">
      {/* Header */}
      {/* <Header /> */}

      {/* Hero Section */}
      <HeroSection id="home" />


      {/* Courses Offered */}
      <section id="courses" className="courses">
        <h2>Our Popular Courses</h2>
        <div className="course-categories">
          <div className="course-category">
          <img style={{width:300}} src={sc}  alt="School Curriculum" />
            <h3>Extra Classes</h3>
            <p>All Subjects, Career Counseling</p>
            <button onClick={openWhatsAppSC}>Learn More</button>
          </div>
          <div className="course-category">
          <img style={{width:300}} src={pr}  alt="Programming" />
            <h3>Master Coding</h3>
            <p>Full Stack, Front-end, Cloud, AI/ML, Web Development, etc.</p>
            <button onClick={openWhatsAppP}>Learn More</button>
          </div>
          <div className="course-category">
          <img style={{width:300}} src={ec} alt="Extracurricular" />
            <h3>Skill Development</h3>
            <p>Chess, Rubik's Cube, Languages etc.</p>
            <button onClick={openWhatsAppEC}>Learn More</button>
          </div>
        </div>
      </section>
      
      {/* Key Benefits Section */}
      <section id="key-benefits" className="key-benefits">
        <h2>Why Choose Us?</h2>
        <div className="benefits">
          <div className="benefit">
           <img style={{width:300}} src={tutor} alt="Expert Instructors" />
            <h3>Expert Instructors</h3>
            <p>Learn from professionals with extensive experience and knowledge in their respective fields</p>
          </div>
          <div className="benefit">
          <img style={{width:300}} src={cl}  alt="InteractiveLearning" />
            <h3>Personalized Learning and Solutions</h3>
            <p>We recognize that one size doesn’t fit all. That’s why we tailor our courses and services to meet your specific needs. Whether you’re a student seeking academic excellence or a business looking to innovate, our customized approach ensures that you achieve your goals effectively.</p>
          </div>
          <div className="benefit">
          <img style={{width:300}} src={fs}  alt="Career Advancements" />
            <h3>Career Advancements</h3>
            <p>Our courses are tailored to enhance your employability and help you advance in your career</p>
          </div>
          <div className="benefit">
          <img style={{width:300}} src={ap}  alt="Supportive Environment" />
            <h3>Supportive Environment</h3>
            <p>We foster a positive and encouraging atmosphere to help students thrive academically and personally</p>
          </div>
          <div className="benefit">
          <img style={{width:300}} src={ap}  alt="Regular Assessments" />
            <h3>Regular Assessments</h3>
            <p>Frequent tests and assessments help track progress and identify areas for improvement</p>
          </div>
        </div>
      </section>
      {/* How It Works */}
      <section>
        <HowItWorks />  
      </section>

      {/* Testimonials */}
      <section>
        <Testimonials />  
      </section>

      {/* Tutor Showcase */}
      {/* <section id="tutors">
      <TutorShowcase />
      </section> */}
      {/* Pricing */}
      {/* <Pricing />  Add the Pricing component here */}
        {/* About Us Section */}
        <section id="about">
        <AboutUs />
      </section>
      

      {/* Blog/Resources */}
      <section id="blog">
        <BlogSection />
      </section>

      {/* Newsletter Signup */}
      <NewsletterSignup />
    
    </div>
  );
}

export default HomePage;
