import React, { useState } from 'react';
import Modal from 'react-modal';
import AWS from 'aws-sdk';
import * as XLSX from 'xlsx';
import { Buffer } from 'buffer';
import './ContactUs.css';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)', // Opaque background
    zIndex: 1000 // High z-index to ensure it appears above other content
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '10px',
    border: 'none',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff', // Ensure background is not transparent
    zIndex: 1001 // Ensure it appears above the overlay
  },
};

Modal.setAppElement('#root');

function ContactUs({ isOpen, onRequestClose }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    enquiry: ''
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await saveToS3(formData);
    setFormSubmitted(true);
    onRequestClose();
  };
  const handleWhatsApp = () => {
    const whatsappMessage = "Hi, I'm interested in your services. Can we discuss further?";
    window.open(`https://wa.me/9380407853?text=${encodeURIComponent(whatsappMessage)}`, '_blank');
  };

  const saveToS3 = async (data) => {
    // AWS Configuration
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION
    });

    const s3 = new AWS.S3();
    const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME;
    const fileName = 'contact_us_data.xlsx';

    try {
      const fileData = await s3.getObject({ Bucket: bucketName, Key: fileName }).promise();
      const fileContent = new Uint8Array(fileData.Body);
      const wb = XLSX.read(fileContent, { type: 'array' });
      const ws = wb.Sheets[wb.SheetNames[0]];
      const dataArray = XLSX.utils.sheet_to_json(ws, { header: 1 });
      dataArray.push([data.name, data.email, data.phone, data.enquiry]);
      const newWS = XLSX.utils.aoa_to_sheet(dataArray);
      const newWB = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(newWB, newWS, 'Sheet1');
      const newExcelBuffer = XLSX.write(newWB, { bookType: 'xlsx', type: 'array' });
      await s3.putObject({ Bucket: bucketName, Key: fileName, Body: Buffer.from(newExcelBuffer) }).promise();
    } catch (error) {
      if (error.code === 'NoSuchKey') {
        const dataArray = [['Name', 'Email', 'Phone', 'Enquiry'], [data.name, data.email, data.phone, data.enquiry]];
        const ws = XLSX.utils.aoa_to_sheet(dataArray);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        await s3.putObject({ Bucket: bucketName, Key: fileName, Body: Buffer.from(excelBuffer) }).promise();
      } else {
        console.log('Error:', error);
      }
    }
  };




  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Contact Us Form"
    >
      <h2 className="contact-us-title">Contact Us</h2>
      {formSubmitted ? (
        <>
          <p className="confirmation-message">Your message was sent successfully!</p>
          <button onClick={handleWhatsApp} className="whatsapp-button">Initiate WhatsApp Conversation</button>
        </>
      ) : (
        <form onSubmit={handleSubmit} className="contact-us-form">
          <label>
            Name:
            <input type="text" name="name" value={formData.name} onChange={handleChange} required />
          </label>
          <label>
            Email:
            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          </label>
          <label>
            Phone:
            <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
          </label>
          <label>
            Enquiry:
            <textarea name="enquiry" value={formData.enquiry} onChange={handleChange} required />
          </label>
          <button type="submit" className="contact-us-submit-button">Submit</button>
        </form>
      )}
      <button onClick={onRequestClose} className="contact-us-close-button">Close</button>
    </Modal>
  );
}

export default ContactUs;
