// FloatingCallIcon.js
import React from 'react';
import './FloatingCallIcon.css';

const FloatingCallIcon = () => {
  const handleCall = () => {
    window.open('tel:+919380407853'); // Replace with your phone number
  };

  return (
    <div className="floating-call-icon" onClick={handleCall}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="white"
        className="bi bi-telephone-fill"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M3.654 1.328a.678.678 0 0 1 1.018-.063L6.29 2.29c.329.329.445.81.293 1.245-.34.985-.448 2.13-.345 3.287.102 1.142.365 2.287.91 3.252.545.964 1.268 1.795 2.224 2.218.981.438 2.066.51 3.08.198.428-.132.897-.014 1.22.294l1.043 1.043c.329.329.39.843.17 1.244a2.125 2.125 0 0 1-2.597 1.052c-1.065-.423-2.06-1.104-2.963-1.935a19.634 19.634 0 0 1-4.746-6.04A19.67 19.67 0 0 1 2.45 3.77a2.125 2.125 0 0 1 1.204-2.442z"
        />
      </svg>
    </div>
  );
};

export default FloatingCallIcon;
