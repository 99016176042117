import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import React from 'react';
import Slider from 'react-slick';
import './Testimonials.css';

const testimonials = [
  {
    quote: "Ekademic has been a game-changer for my child. The personalized lessons and expert tutors have really made a difference!",
    name: "Amit Singh",
    role: "Student"
  },
  {
    quote: "The flexibility and variety of courses offered are amazing. My child enjoys learning new things every day.",
    name: "Sunil Kumar",
    role: "Parent"
  },
  {
    quote: "Great platform for both academic and extracurricular learning. Highly recommend!",
    name: "Ema Kamat",
    role: "Parent"
  },
  {
    quote: "The tutors are very knowledgeable and engaging. My child's performance has improved significantly.",
    name: "Michael D'Souza",
    role: "Parent"
  },
  {
    quote: "We love the personalized attention and customized lesson plans. It has been a wonderful experience.",
    name: "Anita Shetty",
    role: "Parent"
  }
];

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <section className="testimonial-section">
      <h2>What Our Students and Parents Say</h2>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <p className="quote">"{testimonial.quote}"</p>
            <p className="author">- {testimonial.name}, {testimonial.role}</p>
          </div>
        ))}
      </Slider>
    </section>
  );
}

export default Testimonials;
