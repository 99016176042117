import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>
      <p>Welcome to Ekademic LLP Enterprises! By using our e-learning and IT services, you agree to the following terms and conditions. Please read them carefully.
      </p>
      
      <section className="terms-section">
        <h2>1. Acceptance of Terms</h2>
        <p>By accessing and using Ekademic LLP's services, you agree to comply with and be bound by these Terms of Service and all applicable laws and regulations. If you do not agree to these terms, you are prohibited from using our services.

        </p>
      </section>
      
      <section className="terms-section">
        <h2>2. Services Provided</h2>
        <p>Ekademic LLP provides e-learning courses and IT services, including but not limited to:
Online educational courses
IT consultancy
Software development
Data analytics services
</p>
      </section>
      
      <section className="terms-section">
        <h2>3. Restrictions</h2>
        <p>You are specifically restricted from all of the following:</p>
        <ul>
          <li>publishing any Website material in any other media;</li>
          <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
          <li>publicly performing and/or showing any Website material;</li>
          <li>using this Website in any way that is or may be damaging to this Website;</li>
          <li>using this Website in any way that impacts user access to this Website;</li>
          <li>using this Website contrary to applicable laws and regulations, or in any way that may cause harm to the Website, or to any person or business entity;</li>
          <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;</li>
          <li>using this Website to engage in any advertising or marketing.</li>
        </ul>
      </section>
      
      <section className="terms-section">
        <h2>4. Limitation of Liability</h2>
        <p>In no event shall Ekademic, nor any of its officers, directors and employees, be held liable for anything arising out of or in any way connected with your use of this Website.</p>
      </section>

      <section className="terms-section">
        <h2>5. Governing Law & Jurisdiction</h2>
        <p>These Terms will be governed by and interpreted in accordance with the laws of the State of Karnataka, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Karnataka for the resolution of any disputes.</p>
      </section>

      <section>
        <p>3. User Accounts
To access certain features of our services, you may be required to create an account.
You must provide accurate and complete information when creating your account.
You are responsible for maintaining the confidentiality of your account login information and for all activities that occur under your account.
4. Payment and Fees
Some of our services may require payment. You agree to pay all applicable fees and charges for these services.
All payments are non-refundable unless otherwise stated in our refund policy.
5. Intellectual Property
All content, materials, and services provided by Ekademic LLP, including but not limited to text, graphics, logos, and software, are the property of Ekademic LLP or its licensors and are protected by intellectual property laws.
You may not reproduce, distribute, or create derivative works from any content or materials without our express written permission.
6. User Conduct
You agree not to use our services for any unlawful or prohibited activities.
You may not interfere with or disrupt the services or servers or networks connected to the services.
You agree not to post or transmit any harmful, offensive, or inappropriate content.
7. Termination
Ekademic LLP reserves the right to terminate or suspend your account and access to our services at any time, without prior notice, for any reason, including but not limited to violation of these Terms of Service.
8. Disclaimers and Limitation of Liability
Our services are provided "as is" without warranties of any kind, either express or implied.
Ekademic LLP will not be liable for any damages arising from the use of our services, including but not limited to direct, indirect, incidental, punitive, and consequential damages.
9. Changes to Terms
Ekademic LLP reserves the right to modify these Terms of Service at any time. We will notify you of any changes by posting the new terms on our website. Your continued use of the services after any such changes constitutes your acceptance of the new terms.
10. Governing Law
These Terms of Service are governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.

Privacy Policy
Effective Date: [Insert Date]
Ekademic LLP Enterprises is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services.
1. Information We Collect
Personal Information: We may collect personal information such as your name, email address, phone number, and payment information when you register for an account or make a purchase.
Usage Data: We may collect information about how you access and use our services, including your IP address, browser type, and operating system.
2. Use of Information
Provide Services: We use your personal information to provide, maintain, and improve our services.
Communication: We may use your contact information to send you updates, promotional materials, and other information related to our services.
Analytics: We may use usage data to analyze trends, administer the site, and track users' movements to improve our services.
3. Sharing of Information
Third-Party Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as payment processing and data analysis.
Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities.
4. Security
We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.
5. Your Rights
Access and Correction: You have the right to access and update your personal information.
Deletion: You may request the deletion of your personal information, subject to certain legal obligations.
Opt-Out: You may opt out of receiving promotional communications from us by following the unsubscribe instructions provided in those emails.
6. Cookies and Tracking Technologies
We may use cookies and similar tracking technologies to track activity on our services and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
7. Changes to this Privacy Policy
We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.
8. Contact Us
If you have any questions about this Privacy Policy, please contact us at:
Email: contact@ekademic.com
Address: Bangalore, Karnataka
</p>
      </section>
    </div>
  );
}

export default TermsAndConditions;
