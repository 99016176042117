// import React from 'react';
// import './FloatingWhatsAppIcon.css';

// const FloatingWhatsAppIcon = () => {
//     const openWhatsApp = () => {
//         const phoneNumber = '1234567890'; // Replace with your phone number
//         const message = 'Hello! I would like to know more about your services.';
//         const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
//         window.open(url, '_blank');
//     };

//     return (
//         <div className="floating-whatsapp" onClick={openWhatsApp}>
//             <img src="https://img.icons8.com/color/48/000000/whatsapp.png" alt="WhatsApp" />
//         </div>
//     );
// };

// export default FloatingWhatsAppIcon;



// FloatingWhatsAppIcon.js
import React from 'react';
import './FloatingWhatsAppIcon.css';

const FloatingWhatsAppIcon = () => {
    const openWhatsApp = () => {
                const phoneNumber = '9380407853'; // Replace with your phone number
                 const message = '📚 Hello! I am interested in learning more about Ekademic. Could you please provide me with information on courses and enrollment? Thanks!';
                const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
                window.open(url, '_blank');
             };

  return (
    <div className="floating-whatsapp-icon" onClick={openWhatsApp}>
          <img src="https://img.icons8.com/color/48/000000/whatsapp.png" alt="WhatsApp" />
    </div>
  );
};

export default FloatingWhatsAppIcon;
