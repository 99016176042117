// src/components/BlogList.js
import React from 'react';
import './BlogList.css';
import '../BlogSection.css';
import dnaImage from '../assets/dna.jpg';
import rcImage from '../assets/rc.jpg';
import pcImage from '../assets/pc.png';
import { Link } from 'react-router-dom';

const BlogList = ({ blogs }) => {
  return (
    <div className="blog-list">
      {blogs.map(blog => (
        <div   key={blog.id} className="blog-post">
        <img src={blog.image} alt={blog.title}  />
        <div className="post-content">
          <h3>{blog.title}</h3>
          <p className="post-meta">{blog.meta}</p>
          <p>
          {blog.description}
          </p>
          <Link to={`/blogs/${blog.id}`}>
          <button >Read More</button>
          </Link>
        
        </div>
      </div>
      ))}
    </div>
  );
};

export default BlogList;
