import React from 'react';
import Slider from 'react-slick';
import './HeroSection.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import image1 from './assets/maincontent/6.jpg';
import image2 from './assets/maincontent/2.jpg';
import image3 from'./assets/maincontent/3.jpg';
import image4 from'./assets/maincontent/4.jpg';
import image5 from'./assets/maincontent/5.jpg';
import image6 from'./assets/maincontent/6.jpg';

const HeroSection = () => {
  const openWhatsApp = () => {
    const phoneNumber = '9380407853'; // Replace with your phone number
    const message = 'Hi Ekademic Team, I am excited to get started with your educational platform! I am interested in learning more about your services. Looking forward to your response!';
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false
  };

  return (
    <section className="hero">
      <Slider {...settings}>
        <div className="carousel-slide">
          <img src={image1} alt="Slide 1" className="carousel-image" />
          {/* <button className="buy-now-button" onClick={openWhatsApp}>Buy Now</button> */}
        </div>
        <div className="carousel-slide">
          <img src={image2} alt="Slide 2" className="carousel-image" />
          {/* <button className="buy-now-button" onClick={openWhatsApp}>Buy Now</button> */}
        </div>
        <div className="carousel-slide">
          <img src={image3} alt="Slide 3" className="carousel-image" />
        </div>
        <div className="carousel-slide">
          <img src={image4} alt="Slide 4" className="carousel-image" />
        </div>
        <div className="carousel-slide">
          <img src={image5} alt="Slide 5" className="carousel-image" />
        </div>
        <div className="carousel-slide">
          <img src={image6} alt="Slide 6" className="carousel-image" />
        </div>
      </Slider>
    </section>
  );
};

export default HeroSection;


